<template>
  <div>
    <div
      class=""
      v-if="isDateLoaded"
      style="height: 100%; width:100%;background: rgba(0, 0, 0, 0); z-index: 10; position: absolute; "
    >
      <div class="d-flex align-center justify-center" style="height: 100vh">
        <v-progress-circular indeterminate size="78" />
      </div>
    </div>
    <MainLayout>
      <!-- popup подтверждающий удаление -->
      <DeletePopupAdmin
        title="администратора"
        :name="fullName"
        :dialog="dialog"
        @closeDeletePopup="closeDeletePopup"
        :delete-function="deleteAdmin"
      />
      <!-- /popup подтверждающий удаление-->

      <template v-slot:block>
        {{
          sourceObject.results
            ? sourceObject.results.is_active === false
              ? "Заблокирован -"
              : ""
            : ""
        }}
      </template>
      <template v-if="sourceObject.results" v-slot:navbar-title>{{
        sourceObject.results ? sourceObject.results.name : ""
      }}</template>
      <template v-if="sourceObject.results" v-slot:navbar-btn-wrap>
        <DeletePopup
          name="Компанию"
          :title="sourceObject.results ? sourceObject.results.name : ''"
          :delete-function="deleteCompany"
        />
        <BlockPopup
          v-if="sourceObject.results && sourceObject.results.is_active"
          name="Компанию"
          :title="sourceObject.results ? sourceObject.results.name : ''"
          :block-function="blockCompany"
        />
        <UnBlockPopup
          v-if="!(sourceObject.results && sourceObject.results.is_active)"
          name="Компанию"
          :title="sourceObject.results ? sourceObject.results.name : ''"
          :un-block-function="unBlockCompany"
        />
        <router-link
          v-if="urlQuery && urlQuery.id"
          :to="`/companyEdit/` + urlQuery.id"
          class="btn btn-edit btn_color_grey"
          >редактировать</router-link
        >

        <!--      <button class="btn btn-edit btn_color_grey">редактировать</button>-->
      </template>
      <SlideLayout :tabs="slideTabs">
        <template v-slot:info>
          <InfoLayout v-if="sourceObject.results" :tabs="infoTabs">
            <template v-slot:deal>
              <div class="keep__title">
                <p class="keep__text">Договор и оплата</p>
                <!--              <button class="btn btn_color_grey btn-edit">Редактировать</button>-->
              </div>
              <BasicTableWithoutHeader
                :table-data="GetDataDeals"
                v-if="sourceObject !== null"
              >
                <template v-slot:item.0="">
                  <td colspan="2">
                    <div class="d-flex flex-row align-center justify-end">
                      <div class="d-flex align-center justify-center">
                        Просмотр аналитики рабочего времени
                      </div>
                      <v-spacer />
                      <div>
                        <v-switch
                          :input-value="
                            sourceObject.results.allow_visitlog_control
                          "
                          @change="changeVisitlogControl"
                          color="#95c23d"
                          inset
                          :label="
                            sourceObject.results.allow_visitlog_control
                              ? 'Включено'
                              : 'Выключено'
                          "
                        ></v-switch>
                      </div>
                    </div>
                  </td>
                </template>
                <template v-slot:item.2.value="{ item }">
                  <a
                    v-if="sourceObject.results.contract"
                    download
                    :href="item.value"
                  >{{ `Договор № ${sourceObject.results.contract.number}` }}</a
                  >
                  <span v-else>-</span>
                </template>
              </BasicTableWithoutHeader>
            </template>
            <template v-slot:recv>
              <div class="keep__title">
                <p class="keep__text">Реквизиты</p>
              </div>
              <BasicTableWithoutHeader
                :table-data="getDataRequisites"
                v-if="sourceObject !== null"
              />
            </template>
            <template v-slot:admins>
              <div class="keep__title">
                <p class="keep__text">Администраторы</p>
                <router-link
                  to="createAdmin"
                  append
                  class="btn btn-add btn_color_green"
                  >Добавить Администратора</router-link
                >
              </div>
              <BasicTable
                :header="headers"
                :table-data="getDataAdmins.results"
                deleteButtonDirectionStart
              >
                <template v-slot:delete-td-start="{ itemChild }">
                  <td class="btn-delete-wrap">
                    <button
                      @click="clickDelete(itemChild)"
                      class="form__btn-delete btn btn-delete"
                      style="background-color: #eb5c6d"
                    ></button>
                  </td>
                </template>
              </BasicTable>
            </template>
          </InfoLayout>
        </template>
        <template v-slot:objects>
          <CompanyObject />
        </template>
        <template v-slot:controllers>
          <CompanyControllers />
        </template>
        <template v-slot:payments>
          <CompanyPayments />
        </template>

        <template v-slot:users>
          <CompanyUsers />
        </template>
      </SlideLayout>
    </MainLayout>
  </div>
</template>

<script>
import InfoLayout from "@/components/MoreInfo/InfoLayout";
import MainLayout from "@/components/layouts/MainLayout";
import SlideLayout from "@/components/MoreInfo/SlideLayout";
import BasicTable from "@/components/tables/BasicTable";
import BasicTableWithoutHeader from "@/components/tables/BasicTableWithoutHeader";
import * as query from "@/helpers/query";
import CompanyObject from "@/components/info/company/CompanyObject";
import CompanyControllers from "@/components/info/company/CompanyControllers";
import CompanyPayments from "@/components/info/company/CompanyPayments";
import CompanyUsers from "@/components/info/company/CompanyUsers";
import DeletePopup from "@/components/popup/DeletePopup";
import DeletePopupAdmin from "@/components/popup/DeletePopupCommon";
import { dictionariesHelper } from "@/helpers/dictionariesHelper";
import moment from "moment";
import BlockPopup from "@/components/popup/BlockPopup";
import UnBlockPopup from "@/components/popup/UnBlockPopup";
import { patchOrganizationsByIdRequest } from "@/helpers/api/organizations";
import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";
import phoneFilter from "@/helpers/filters/phoneFilter";
export default {
  name: "CompanyInfosPages",
  components: {
    UnBlockPopup,
    BlockPopup,
    DeletePopup,
    DeletePopupAdmin,
    BasicTableWithoutHeader,
    BasicTable,
    SlideLayout,
    MainLayout,
    InfoLayout,
    CompanyObject,
    CompanyControllers,
    CompanyPayments,
    CompanyUsers
  },
  data: () => ({
    dialog: false,
    idAdmin: "", // id админа
    fullName: "",
    isDateLoaded: false,

    queries: query.organizations,
    headers: [
      {
        value: "id",
        text: "ID"
      },
      {
        renderFunction: value => {
          return `<div>${value.last_name || ""} ${value.first_name ||
            ""} ${value.middle_name || ""}</div>`;
        },
        text: "ФИО"
      },
      {
        renderFunction: value => phoneFilter.execute(value?.phone_number),
        text: "Телефон"
      }
    ]
  }),
  computed: {
    infoTabs() {
      return [
        {
          text: "Договор и оплата",
          value: "deal"
        },
        {
          text: "Реквизиты",
          value: "recv"
        },
        {
          text: "Администраторы",
          value: "admins"
        }
      ];
    },
    sourceObject() {
      return this.$store.getters.getDealerInfo;
    },
    getDataAdmins() {
      return this.$store.getters.getAdmins;
    },
    GetDataDeals() {
      return [
        {},
        {
          text: " Дилер",
          value: this.sourceObject.results.diller_details
            ? this.sourceObject.results.diller_details.name
            : "-"
        },
        {
          text: "Файл (скан) договора",
          value: this.sourceObject.results.contract
            ? this.sourceObject.results.contract.file
            : "#"
        },
        {
          text: "Номер договора",
          value: this.sourceObject.results.contract
            ? this.sourceObject.results.contract.number
            : "-"
        },
        {
          text: "Дата договора",
          value: this.sourceObject.results.contract
            ? moment(this.sourceObject.results.created_at * 1000).format(
                "DD.MM.YYYY"
              )
            : "-"
        },

        {
          text: "Тип оплаты",
          value:
            dictionariesHelper.acquiringEntity[
              this.sourceObject.results.acquiring_entity
            ]
        },
        {
          text: "Эквайринг",
          value:
            dictionariesHelper.acquiringProvider[
              this.sourceObject.results.acquiring_provider
            ]
        }
      ];
    },
    getDataRequisites() {
      return [
        {
          text: "Полное название организации",
          value: this.sourceObject.results.full_org_name
        },
        {
          text: "Краткое название организации",
          value: this.sourceObject.results.name
        },
        {
          text: "Город",
          value: this.sourceObject.results?.city_details?.name || "-"
        },
        {
          text: "Тип",
          value:
            dictionariesHelper.organizationOwnership[
              this.sourceObject.results.ownership
            ]
        },
        {
          text: "Руководитель",
          value: this.sourceObject.results.director
        },
        {
          text: "ИНН",
          value: this.sourceObject.results.TIN
        },
        {
          text: "ОРГН",
          value: this.sourceObject.results.PSRN
        },
        {
          text: "БИК",
          value: this.sourceObject.results.RCBIC
        },
        {
          text: "КПП",
          value: this.sourceObject.results.IEC
        },
        {
          text: "Название банка",
          value: this.sourceObject.results.bank
        },
        {
          text: "К/c",
          value: this.sourceObject.results.CA
        },
        {
          text: "Р/c",
          value: this.sourceObject.results.giro
        },
        {
          text: "Адрес организации",
          value: this.sourceObject.results.address || "-"
        },
        {
          text: "Фактический адрес организации",
          value: this.sourceObject.results.actual_address || "-"
        },

        {
          text: "Телефон",
          value: phoneFilter.execute(this?.sourceObject?.results?.phone_number)
        },
        {
          text: "E-mail",
          value: this.sourceObject.results.email
        }
      ];
    },
    urlQuery() {
      return {
        query: this.$route.query,
        id: this.$route.params.id
      };
    },
    slideTabs() {
      return [
        {
          text: "Общая информация",
          value: "info",
          icon: "i"
        },
        {
          text: "Объекты",
          value: "objects",
          count: this.sourceObject.results
            ? this.sourceObject.results.objects_count
            : "",
          icon: null
        },
        {
          text: "Контроллеры",
          value: "controllers",
          count: this.sourceObject.results
            ? this.sourceObject.results.controllers_count
            : "",
          icon: null
        },

        {
          text: "Пользователи",
          value: "users",
          count: this.sourceObject.results
            ? this.sourceObject.results.users_count
            : "",
          icon: null
        },
        {
          text: "Платежи",
          value: "payments",
          icon: null
        }
      ];
    }
  },
  methods: {
    changeVisitlogControl(value) {
      patchOrganizationsByIdRequest({
        id: this.urlQuery.id,
        data: { allow_visitlog_control: value }
      });
    },
    deleteCompany() {
      this.$store
        .dispatch("deleteCompany", {
          id: this.urlQuery.id
        })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.remove(successMessageDictionary.company)
          );
        });
    },
    blockCompany() {
      this.$store
        .dispatch("blockOrganization", {
          id: this.urlQuery.id
        })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.ban(successMessageDictionary.company)
          );
        });
    },
    unBlockCompany() {
      this.$store
        .dispatch("unBlockOrganization", {
          id: this.urlQuery.id
        })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.unBan(successMessageDictionary.company)
          );
        });
    },

    clickDelete(obj) {
      this.idAdmin = obj.id;
      this.fullName = `${obj.last_name} ${obj.first_name} ${obj.middle_name}`;
      this.dialog = true;
    },

    closeDeletePopup(data) {
      this.dialog = data;
    },

    deleteAdmin() {
      this.isDateLoaded = true;
      this.$store
        .dispatch("deleteAdmin", {
          id: this.idAdmin,
          organizationId: this.$route.params.id
        })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.remove(successMessageDictionary.admin)
          );
        })
        .finally(() => {
          this.$store.dispatch("fetchAdmins").finally(() => {
            this.isDateLoaded = false;
          });
        });
    }
  },
  created() {
    this.isDateLoaded = true;
    localStorage.removeItem("ObjectId");
    this.$store.commit("setSearchQuery", {
      id: this.urlQuery.id,
      query: { ...this.urlQuery.query }
    });

    Promise.all([
      this.$store.dispatch("fetchDealerInfo"),
      this.$store.dispatch("fetchAdmins")
    ]).finally(() => {
      this.isDateLoaded = false;
    });
  },
  beforeRouteUpdate(to, from, next) {
    if (to.path === from.path) {
      this.$store.commit("setSearchQuery", {
        id: this.urlQuery.id,
        query: {
          ...to.query
        }
      });
      this.isDateLoaded = true;
      this.$store.dispatch("fetchDealerInfo").finally(() => {
        this.isDateLoaded = false;
      });
      this.isDateLoaded = true;
      this.$store.dispatch("fetchAdmins").finally(() => {
        this.isDateLoaded = false;
      });
    }
    next();
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch("clearOrganizationPage").then(() => next());
  }
};
</script>

<style scoped></style>
