<template>
  <div>
    <TableFilters :title-count="sourceObject.count" :queries="queries" :loading="isDateLoaded">
      <template v-slot:download-button>
        <v-btn
          @click="downloadList"
          class="btn btn_color_grey btn-unload flex-right mr-4"
          :disabled="loadingBtn || sourceObject.results.length == 0"
          :loading="loadingBtn"
        >
          Выгрузить
        </v-btn>
      </template>
      <!--      <template v-slot:add-button>-->
      <!--        <router-link to="createUser" append class="btn btn-add btn_color_green"-->
      <!--          >Добавить пользователя</router-link-->
      <!--        >-->
      <!--      </template>-->
    </TableFilters>
    <TableLayout
      :loading="isDateLoaded"
      to="users"
      :table-data="sourceObject.results"
      :header="header"
      :source-object="sourceObject"
    />
  </div>
</template>

<script>
import TableLayout from "@/components/tables/TableLayout";
import * as query from "@/helpers/query";
import TableFilters from "@/components/tables/TableFilters";
import tableColsUsers from "@/helpers/tableCols/User";
export default {
  name: "CompanyUsers",
  data() {
    return {
      loadingBtn: false,
      queries: query.users.filter(el => el.type === "search")
      // type: "default",
    };
  },
  components: { TableFilters, TableLayout },
  computed: {
    header() {
      return tableColsUsers.filter(
        el =>
          el.value == "id" ||
          el.value == "last_name" ||
          el.value == "phone_number" ||
          el.value == "is_phone_confirmed" ||
          el.value == "objects_count" ||
          el.value == "total_payments" ||
          el.value == "is_active"
      );
    },
    sourceObject() {
      return this.$store.getters.getOrganizationUsersList;
    },
    urlQuery() {
      return {
        query: this.$route.query,
        id: this.$route.params.id
      };
    }
  },
  methods: {
    downloadList() {
      this.loadingBtn = true;
      this.$store
        .dispatch("downloadOrganizationUsersList", this.header)
        .then(() => {
          this.loadingBtn = false;
        });
    }
  },
  created() {
    this.$store.dispatch("clearSortQuery");
    this.$store.commit("setSearchQuery", {
      query: { ...this.urlQuery.query, groups__organization: this.urlQuery.id },
      id: this.urlQuery.id
    });
    this.isDateLoaded = true;
    this.$store.dispatch("fetchOrganizationUsersList").finally(() => {
      this.isDateLoaded = false;
    });
  },
  watch: {
    $route(to, from) {
      if (to.path === from.path) {
        this.$store.commit("setSearchQuery", {
          id: this.urlQuery.id,
          query: { ...to.query, groups__organization: this.urlQuery.id }
        });
        this.isDateLoaded = true;
        this.$store.dispatch("fetchOrganizationUsersList").finally(() => {
          this.isDateLoaded = false;
        });
      }
    }
  }
  // beforeRouteLeave(to, from, next) {
  //   this.$store.dispatch("clearUsersPage").then(() => next());
  // }
};
</script>

<style scoped></style>
